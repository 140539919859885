import React, { useEffect, useState, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../api";
import { useAuth } from "../../context/AuthContext";
import { useCurrency } from "../../context/CurrencyContext";
import { useCart } from "../../context/CartContext";

import LiveSocial from "./LiveSocial";
import LatestArrivals from "./LatestArrivals";
import BestSeller from "./BestSeller";
import VisitStoreDigitally from "./VisitStoreDigitally";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const Banner = lazy(() => import("./Banner"));
const WeddingStore = lazy(() => import("./WeddingStore"));

const CACHE_KEY = "homePageData";
const CACHE_DURATION = 3600000;

const HomePage = () => {
  const { logout } = useAuth();
  const { setCurrencyPreference } = useCurrency();
  const navigate = useNavigate();

  const { setCartCount } = useCart();
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let api_token = localStorage.getItem("apiToken");
    const fetchData = async () => {
      setLoading(true);
      try {
        const clientId = localStorage.getItem("clientId");
        const userRole = localStorage.getItem("userRole");

        if (userRole === "guest" && !api_token.startsWith("guest_")) {
          api_token = "guest_" + api_token;
        }

        const response = await api.post("/user/user_home_web", {
          api_token,
          app_type: "web",
          app_version: "1.0",
          country_code: "US",
          advertiser_id: "",
          device_token: clientId,
        });

        if (response.data.status === 200) {
          const cartResponse = await api.post("/cart/create_cart", {
            api_token,
          });

          if (cartResponse.data && cartResponse.data.cart_data) {
            const { cart_id, order_num, item_count } =
              cartResponse.data.cart_data;

            localStorage.setItem("cart_id", cart_id);
            localStorage.setItem("order_num", order_num);
            localStorage.setItem("item_count", item_count);
            localStorage.setItem("cartCount", item_count);

            if (typeof item_count === "number" && !isNaN(item_count)) {
              setCartCount(item_count);
            } else {
              console.error("Invalid item_count:", item_count);
            }
          } else {
            console.error("Cart data is missing:", cartResponse.data);
          }

          setData(response.data);

          localStorage.setItem(
            "userCountryCode",
            response.data.user_data.base_country_code
          );
          localStorage.setItem("userUuid", response.data.user_data.user_uuid);
        } else if (response.data.status === 401) {
          logout();
          navigate("/home");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (api_token != null) {
      fetchData();
    }
  }, [logout, setCartCount, navigate]);

  return (
    <div className="main-content">
      <Suspense fallback={<Skeleton height={800} count={1} />}>
        {data.slider_1 ? (
          <Banner banners={data.slider_1} />
        ) : (
          <Skeleton height={800} count={1} />
        )}
      </Suspense>

      {loading ? (
        <>
          <BestSeller loading={loading} />
          <VisitStoreDigitally loading={loading} />
          <LiveSocial loading={loading} />
          <VisitStoreDigitally loading={loading} />
          <LatestArrivals loading={loading} />
        </>
      ) : (
        <>
          {data.bestseller && <BestSeller products={data.bestseller} />}
          <Suspense fallback={<Skeleton height={800} count={1} />}>
            {data.slider_2 ? (
              <WeddingStore sliderData={data.slider_2} />
            ) : (
              <Skeleton height={800} count={1} />
            )}
          </Suspense>
          {data.live_social && <LiveSocial videos={data.live_social} />}
          {data.digital_stores && (
            <VisitStoreDigitally digital_stores={data.slider_3} />
          )}
          {data.latest_arrival && (
            <LatestArrivals products={data.latest_arrival} />
          )}
        </>
      )}
    </div>
  );
};

export default HomePage;
