import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import api from "../../api";
import { useAuth } from "../../context/AuthContext";
import { useForm } from "react-hook-form";
import { CheckoutContext } from "../../context/CheckoutContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OrderProcessing from "./OrderProcessing";
import PostalCodeSelector from "../PostalCodeSelector";
import CheckoutForm from "./CheckoutForm";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Checkout = () => {
  const {
    existingAddress,
    addAddress,
    calculateCredit,
    currency,
    symbol,
    remaining,
    couponType,
    shippingData,
    shipObjectId,
    shipLocalAmount,
    totalAmount,
    totalIncludeShipping,
    showShipping,
  } = useContext(CheckoutContext);
  const [error, setError] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [showOrderProcessing, setShowOrderProcessing] = useState(false);
  const [shippingCost, setShippingCost] = useState(0);
  const [totalIncludeShippingData, setTotalIncludeShipping] =
    useState(totalIncludeShipping);
  const [remainingData, setRemaining] = useState(remaining);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState(0);
  const [phoneValue, setPhoneValue] = useState("");
  const [checkOutData, setCheckOutData] = useState([]);
  const [orderData, setOrderData] = useState(null);
  const [postalCode, setPostalCode] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lon: null });
  const [fullAddress, setFullAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleAddressFetched = (address) => {
    setFullAddress(address);
  };

  const fetchCartDetails = async () => {
    try {
      const order_num = localStorage.getItem("order_num");
      const response = await api.get("/cart/get_cart_details", {
        params: {
          api_token: localStorage.getItem("apiToken"),
          order_num,
        },
      });
      if (response.data.status === 401) {
        logout();
        navigate("/home");
      } else if (response.status === 200) {
        setCheckOutData(response.data.shopping_cart_data);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to fetch cart details:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCartDetails();
  }, []);

  const handlePhoneChange = (value) => {
    const phoneNumber = value.replace(/^\+/, "");
    const countryCode = phoneNumber.slice(0, 2);
    const numberWithoutCountryCode = phoneNumber.slice(2);
    const formattedPhone = `${countryCode}-${numberWithoutCountryCode}`;

    setPhoneValue(formattedPhone);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      address_1: "",
      address_2: "",
      state: "",
      zipcode: "",
      city: "",
      country: "",
    },
  });

  useEffect(() => {
    if (existingAddress?.user_address?.length > 0) {
      const existing = existingAddress.user_address[0] || {};
      setPhoneValue(existing.phone);
      reset({
        name: existing.shipping_name || "",
        email: existing.user_address_email || "",
        phone: existing.phone || "",
        address_1: existing.address_1 || "",
        address_2: existing.address_2 || "",
        state: existing.state || "",
        zipcode: existing.zipcode || "",
        city: existing.city || "",
        country: existing.country || "",
      });
    }
  }, [existingAddress, reset]);

  useEffect(() => {
    if (shippingData.length > 0) {
      const firstOptionCost = parseFloat(shippingData[0].amount_local);
      setShippingCost(firstOptionCost);
      setTotalIncludeShipping(totalAmount + firstOptionCost);
    }
  }, [shippingData, totalAmount]);

  const onSubmit = (data) => {
    if (existingAddress?.user_address?.length > 0) {
      const existing = existingAddress.user_address[0] || {};
      const isUpdate = {
        address_id: existing.address_id || "",
        ...data,
      };
      addAddress(existingAddress?.user_address.length ? isUpdate : data);
    } else {
      addAddress(data);
    }
  };

  const handleDeliveryOptionChange = (price, index) => {
    const shipAmount = parseFloat(price);
    let newTotalIncludeShipping = 0;

    if (totalAmount === 0) {
      if (shipAmount > remaining) {
        newTotalIncludeShipping = shipAmount - remaining;
      } else {
        newTotalIncludeShipping = 0;
      }
    } else {
      newTotalIncludeShipping = totalAmount + shipAmount;
    }

    setShippingCost(shipAmount);
    setTotalIncludeShipping(newTotalIncludeShipping);
    setSelectedDeliveryOption(index);
  };

  const formatAmount = (amount) => {
    return `${symbol}${amount.toFixed(2)}`;
  };

  const handlePlaceOrder = () => {
    const order_num = localStorage.getItem("order_num");
    const apiToken = localStorage.getItem("apiToken");
    const orderDataDetails = {
      address_id:
        existingAddress?.user_address?.length > 0
          ? existingAddress.user_address[0].address_id
          : "",
      api_token: apiToken,
      apply_credits: 0,
      coupon_id: checkOutData.coupon_id,
      coupon_owner_id: checkOutData.coupon_owner_id,
      order_num: checkOutData.order_num,
      ship_local_amount: shipLocalAmount,
      ship_object_id: shipObjectId,
    };
    setOrderData(orderDataDetails);
    setShowOrderProcessing(true);
  };

  const handleCloseModal = () => {
    setShowOrderProcessing(false);
  };

  return (
    <div className="main-content">
      <div className="container checkout">
        {!existingAddress?.user_address?.[0]?.address_id ? (
          <div className="checkout-left inactive">
            <h2>Delivery Address</h2>
            <div className="checkout-alert">
              {loading ? (
                <Skeleton height={50} width={50} />
              ) : (
                <img src="/images/truck.svg" alt="Delivery Truck" />
              )}
              <div className="info">
                <h4>
                  {loading ? <Skeleton width={150} /> : "We are here for you"}
                </h4>
                <span>
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    "Delivering safely to your door"
                  )}
                </span>
              </div>
            </div>

            <div className="form-block fomr-block">
              <h4>{loading ? <Skeleton width={100} /> : "Add Address"}</h4>

              <CheckoutForm
                existingAddress={existingAddress}
                addAddress={addAddress}
                loading={loading}
              />
            </div>

            {showOrderProcessing && (
              <OrderProcessing
                CheckoutorderData={orderData}
                totalAmountData={
                  totalIncludeShippingData
                    ? totalIncludeShippingData
                    : totalIncludeShipping
                }
                symbol={symbol}
                showModal={showOrderProcessing}
                handleCloseModal={handleCloseModal}
              />
            )}
          </div>
        ) : (
          <>
            <div className="checkout-left  active">
              <h2>Delivery Address</h2>
              <div className="checkout-alert">
                {loading ? (
                  <Skeleton height={50} width={50} />
                ) : (
                  <img src="/images/truck.svg" alt="Delivery Truck" />
                )}
                <div className="info">
                  <h4>
                    {loading ? <Skeleton width={150} /> : "We are here for you"}
                  </h4>
                  <span>
                    {loading ? (
                      <Skeleton width={200} />
                    ) : (
                      "Delivering safely to your door"
                    )}
                  </span>
                </div>
              </div>

              <div className="form-block fomr-block">
                <h4>{loading ? <Skeleton width={100} /> : "Add Address"}</h4>

                <CheckoutForm
                  existingAddress={existingAddress}
                  addAddress={addAddress}
                  loading={loading}
                />
              </div>
            </div>

            <div className="checkout-right">
              <div className="items-cart">
                <h3>{loading ? <Skeleton width={100} /> : "Your Order"}</h3>
                <div className="apply" disabled>
                  {loading ? (
                    <Skeleton height={40} />
                  ) : (
                    <>
                      <input
                        type="text"
                        placeholder="Enter Credit Code"
                        disabled
                      />
                      <button type="button" disabled="disabled">
                        APPLY
                      </button>
                    </>
                  )}
                </div>
                <p className="credit">
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    "You can use up to 0 credits for this purchase"
                  )}
                </p>

                <div className="delivery-option">
                  <h4>
                    {loading ? <Skeleton width={100} /> : "Delivery Options"}
                  </h4>
                  <div className="select-option">
                    <ul>
                      {loading
                        ? [1, 2, 3].map((_, idx) => (
                            <li key={idx}>
                              <Skeleton height={30} width={"100%"} />
                            </li>
                          ))
                        : shippingData.map((option, index) => (
                            <li
                              key={index}
                              className={
                                selectedDeliveryOption === index ? "active" : ""
                              }
                            >
                              <label>
                                <input
                                  type="radio"
                                  className={`checkbox ${index} ${
                                    selectedDeliveryOption === index
                                      ? "active"
                                      : ""
                                  }`}
                                  name="delivery-option"
                                  value={option.amount_local}
                                  checked={selectedDeliveryOption === index}
                                  onChange={() =>
                                    handleDeliveryOptionChange(
                                      option.amount_local,
                                      index
                                    )
                                  }
                                />
                                <span className="text-">
                                  {option.Delivery_Time}
                                  <span>
                                    {option.Currency}
                                    {option.amount_local}
                                  </span>
                                </span>
                              </label>
                            </li>
                          ))}
                    </ul>
                  </div>
                </div>

                <table className="total">
                  <tbody>
                    <tr>
                      <td>Subtotal</td>
                      <td>
                        {loading ? (
                          <Skeleton width={60} />
                        ) : (
                          formatAmount(totalIncludeShipping)
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Delivery</td>
                      <td>
                        {loading ? (
                          <Skeleton width={60} />
                        ) : (
                          formatAmount(shippingCost)
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        {loading ? (
                          <Skeleton width={60} />
                        ) : (
                          formatAmount(
                            totalIncludeShippingData
                              ? totalIncludeShippingData
                              : totalIncludeShipping
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="Place-Order">
                        {loading ? (
                          <Skeleton height={40} width={120} />
                        ) : (
                          <button
                            className="btn btn-primary stripe_payment for_open_popup"
                            onClick={handlePlaceOrder}
                          >
                            Place Order
                          </button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {showOrderProcessing && !loading && (
              <OrderProcessing
                CheckoutorderData={orderData}
                totalAmountData={
                  totalIncludeShippingData
                    ? totalIncludeShippingData
                    : totalIncludeShipping
                }
                symbol={symbol}
                showModal={showOrderProcessing}
                handleCloseModal={handleCloseModal}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Checkout;
